import { ServiceCharacteristicCallback } from '../interfaces/HpsdConfigCallback';
import { InternetFacility, ServiceCharacteristic } from '../interfaces/InternetFacility';
import { useMemo } from 'react';

const useHpsd = (dataFacility?: InternetFacility) => {

  /**
    * Get the attributes of Geral Characteristics returned from /tmf-638/facilities
    */
  const geralCharacteristic = useMemo(() => {
    return dataFacility?.serviceCharacteristic?.find((char) => char.name === 'GERAL')?.value;
  }, [dataFacility]);

  /**
   *  Return if the designador type is ERB
   */
  const isErb = useMemo((): boolean => {
    if (geralCharacteristic && geralCharacteristic.TIPO_ACESSO === "ERB") {
      return true;
    }

    return false;
  }, [geralCharacteristic]);

  /**
   * Map and return the serviceCharacteristic of the serviceName searched of the HPSD Facilidade response
   * @param serviceCharacteristic 
   * @param serviceName 
   * @returns 
   */
  const findServiceCharacteristicValue = (
    serviceCharacteristic: ServiceCharacteristic[],
    serviceName: string
  ) => {
    return serviceCharacteristic.find((service) => service.name === serviceName)?.value;
  };

  /**
   * Map and return the serviceCharacteristic of the serviceName searched of the HPSD Callback response
   * @param serviceCharacteristic 
   * @param serviceName 
   * @returns 
   */
  const findServiceCharacteristicCallbackValue = (
    serviceCharacteristic: ServiceCharacteristicCallback[],
    serviceName: string
  ) => {
    return serviceCharacteristic.find((service) => service.name === serviceName)?.value;
  };

  return { geralCharacteristic, findServiceCharacteristicValue, findServiceCharacteristicCallbackValue, isErb };

};

export default useHpsd;